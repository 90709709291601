import React, { useState, useRef, useEffect } from "react";

const VideoAccordion = ({ question, videoSrc, isVisible, text, isActive, onToggle }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const videoRef = useRef(null);

    // pause video if accordion is closed
    useEffect(() => {
        if (!isActive && videoRef.current) {
            videoRef.current.pause();
        }
    }, [isActive]);

    if (!isVisible) {
        return null; // Do not render if not visible
    }


    return (
        <div className="accordion">
            <div className="accordion-header" onClick={onToggle}>
                <h6>{question}</h6>
                <span>{isActive ? "-" : "+"}</span>
            </div>
            {isActive && (
                <div className="accordion-content">
                    <video ref={videoRef} controls width="680px">
                        <source src={videoSrc} type="video/mp4"/>
                        Ihr Browser unterstützt das video nicht.
                    </video>
                </div>
            )}
            {isActive && (
                <div className="accordion-footer">
                    <span>{text}</span>
                </div>
            )}
        </div>
    );
};

export default VideoAccordion;
