import React from 'react'
import {Navigate} from "react-router-dom";
import PageContactDetail from "../web/contact/Contact";
import Pricing from "../web/pricing/Pricing";
import Home from "../web/home";
import AGB from "../web/agb/AGB";
import API from "../web/api/API";
import Impressum from "../web/impressum/Impressum";
import News from "../web/news/News";
import Migration from "../web/migrate/Migration";
import LearningVideos from "../web/help/LearningVideos";

const routes = [
    {
        path: "/",
        exact: true,
        component: <Navigate to="/index.html"/>,
    },
    {path: "/index.html", component: <Home/>, isTopbarDark: true},
    {path: "/angebot.html", component: <Pricing/>, isTopbarDark: true},
    {path: "/lernvideos.html", component: <LearningVideos/>, isTopbarDark: true},
    {path: "/kontakt.html", component: <PageContactDetail/>, isTopbarDark: true},
    {path: "/agb.html", component: <AGB/>, isTopbarDark: true},
    {path: "/api.html", component: <API/>, isTopbarDark: true},
    {path: "/impressum.html", component: <Impressum/>, isTopbarDark: true},
    {path: "/news.html", component: <News/>, isTopbarDark: true},
    {path: "/migrieren.html", component: <Migration/>, isTopbarDark: true},

];

export default routes;
