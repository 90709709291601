import React, { Component, useState } from "react";
import { Container, Row, Col } from "reactstrap";
//Import components
import ScrollspyNav from "./ScrollspyNav";
import VideoAccordion from "./VideoAccordion";
// import http from "http";

class LearningVideos extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeAccordion: null, // Track the active accordion
      searchTerm: "", // State to hold the search term
    };

    this.questions = [
      {
        id: "1",
        section: "newfibu3",
        question: "Wie melde ich mich bei fibu3 an ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/register.mp4",
        keywords: ["neu", "registrieren", "zugang", "registration", "anmelden"],
        text: "Neu bei fibu3? Dieses Video zeigt Ihnen die notwendigen Schritte um sich einzuloggen."
      },
      {
        id: "2",
        section: "newfibu3",
        question: "Wie initialisiere ich eine neue Buchunghaltungssperiode ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/initfibu.mp4",
        keywords: ["neu", "buchungsperiode", "periode", "buchhaltung", "initialisieren", "konfiguration", "initialisation"],
        text: "Dieses Video zeigt Ihnen, wie Sie eine neue Buchhaltungsperiode initialisieren."
      },
      {
        id: "3",
        section: "newfibu3",
        question: "Welche Funktionen bietet mir fibu3 - Ein kurzer Überblick ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/overview.mp4",
        keywords: ["neu", "fibu3", "funktionen", "grundfunktionen", "überblick", "funktionsüberblick", "einführung", "bietet"],
        text: "Dieses Video gibt Ihnen einen kurzen Überblick über die Grundfunktionen, welche Ihnen fibu3 bietet."
      },
      {
        id: "4",
        section: "newfibu3",
        question: "Was sehe ich auf der Übersichtsseite ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/dashboard.mp4",
        keywords: ["neu", "dashboard", "übersicht", "übersichtsseite", "charts", "überblick"],
        text: "Dieses Video stellt Ihnen das Dashboard vor."
      },
      {
        id: "5",
        section: "newfibu3",
        question: "Was bietet mir die Bilanzseite ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/dashboard.mp4",
        keywords: ["bilanz", "budget", "vorjahreszahlen", "gewinn", "verlust", "report", "konti", "auswertung"],
        text: "Dieses Video zeigt Ihnen die Funktionen der Bilanzseite."
      },
      {
        id: "6",
        section: "newfibu3",
        question: "Was bietet mir die Erfolgsrechnungsseite ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/er.mp4",
        keywords: ["erfolgsrechnung", "budget", "gewinn", "verlust", "report", "konti", "auswertung"],
        text: "Dieses Video zeigt Ihnen die Funktionen der Erfolgsrechnungsseite."
      },

      {
        id: "19",
        section: "accounting",
        question: "Wie funktioniert der intelligente Buchungsassistent ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/ai.mp4",
        keywords: ["buchhaltung", "journal", "buchung", "buchen", "ai", "assistent", "intelligenter assistent"],
        text: "Dieses Video zeigt Ihnen die Funktionen des intelligenten Buchungsassistenten."
      },
      {
        id: "20",
        section: "accounting",
        question: "Wie erstelle ich eine neue Journalbuchung ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/journal-overview.mp4",
        keywords: ["buchhaltung", "journal", "buchung", "buchen", "buchung anpassen", "buchung löschen", "vorlage", "buchungsvorlage"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Journalseite."
      },
      {
        id: "21",
        section: "accounting",
        question: "Wie erstelle ich einen Debitor ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/debtor.mp4",
        keywords: ["buchhaltung", "debitor", "buchung", "buchen", "debitor anpassen", "debitor löschen", "zahlung", "zahlung verbuchen"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Debitorenseite."
      },
      {
        id: "22",
        section: "accounting",
        question: "Wie erstelle ich einen Kreditor ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/creditor.mp4",
        keywords: ["buchhaltung", "kreditor", "buchung", "buchen", "kreditor anpassen", "kreditor löschen", "zahlung", "zahlung verbuchen"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Kreditorenseite."
      },
      {
        id: "23",
        section: "accounting",
        question: "Wie führe ich einen Bankabgleich durch ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/bankabgleich.mp4",
        keywords: ["buchhaltung", "debitor", "bank", "bankabgleich"],
        text: "Dieses Video zeigt Ihnen, wie der Bankabgleich funktioniert."
      },

      {
        id: "50",
        section: "article",
        question: "Wie funktioniert die Artikelverwaltung ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/article.mp4",
        keywords: ["artikel", "artikelverwaltung", "rechnung", "offerte", "artikel anpassen", "artikel löschen"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Artikelseite."
      },


      {
        id: "70",
        section: "offerInvoice",
        question: "Wie funktioniert die Auftragsverwaltung ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/order.mp4",
        keywords: ["auftrag", "auftragsverwaltung", "rechnung", "offerte", "mahnung", "qr code"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Auftragsseite, wo Sie Offerten, Rechnungen und Mahnungen verwalten können."
      },

      {
        id: "100",
        section: "general",
        question: "Ich habe eine weitere Frage, ich brauche Unterstützung ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/support.mp4",
        keywords: ["hilfe", "support", "anmerkung", "frage", "kommentar", "fehlende funktion", "melden"],
        text: "Dieses Video zeigt Ihnen, wie Sie den fibu3 Support erreichen können."
      },
      {
        id: "101",
        section: "general",
        question: "Wie erstelle ich einen Partner ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/partner.mp4",
        keywords: ["partner", "partner erstellen", "partner anpassen", "fakturen anzeigen", "faktura", "partner löschen"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Partnerseite."
      },
      {
        id: "102",
        section: "general",
        question: "Wie erstelle ich eine Auswertung ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/reports.mp4",
        keywords: ["report", "auswertung", "auswertungen", "chart", "grafische darstellung", "aktiva", "passiva", "budget", "erfolgsrechnung", "bilanz", "mwst", "abrechnung"],
        text: "Dieses Video zeigt Ihnen die Funktionen auf der Auswertungsseite."
      },
      {
        id: "103",
        section: "general",
        question: "Welche Einstellungen gibt es ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/settings.mp4",
        keywords: ["einstellungen", "firmendaten", "bankdaten", "grunddaten", "basisdaten", "konfiguration", "schlusswerte", "entsperren", "währung", "fremdwährung", "euro", "eur", "usd", "kontenrahmen", "mehrwertsteuer", "benutzer", "mwst", "exportieren", "export", "buchungsperiode", "jahresabschluss", "fremdwährung", "währung", "vorlagen"],
        text: "Dieses Video zeigt Ihnen die verschiedenen Einstellungsmöglichkeiten und das Erfassen von Basisdaten in fibu3."
      },
      {
        id: "104",
        section: "general",
        question: "Wie verwalte ich mehrere Mandanten ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/mandator.mp4",
        keywords: ["mandanten", "mandant", "mehrere buchhaltungen", "professional", "mandantenverwaltung"],
        text: "Dieses Video zeigt Ihnen, wie Sie in der Professional Version, mehrere Mandanten verwalten können."
      },
      {
        id: "105",
        section: "general",
        question: "Wie führe ich ein Upgrade auf ein anderes Abo durch ?",
        videoSrc: "https://fibu3storageprod.blob.core.windows.net/fibu3web/videos/upgrade.mp4",
        keywords: ["upgrade", "standard", "version", "professional", "funktionen"],
        text: "Dieses Video zeigt Ihnen, wie Sie ein Upgrade auf ein anderes fibu3 Abo machen."
      },


    ];
  }



  render() {
    const categories = ["newfibu3", "accouting", "article", "offerInvoice", "general"];
    const { activeAccordion, searchTerm } = this.state;

    const handleSearchChange = (event) => {
      this.setState({ searchTerm: event.target.value });
    };

    const handleAccordionToggle = (id) => {
      this.setState((prevState) => ({
        activeAccordion: prevState.activeAccordion === id ? <div></div> : id,
      }));
    };

    const getFilteredQuestions = (sectionId) => {
          return this.questions
              .filter((item) => item.section === sectionId)
              .map((item) => ({
                ...item,
                isVisible:
                    searchTerm.trim() === "" ||
                    item.keywords.some((keyword) =>
                        keyword.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
              }));
    };

    // check if any section has visible questions
    const hasVisibleQuestions = categories.some(
        (section) =>
            getFilteredQuestions(section).filter(({ isVisible }) => isVisible).length > 0
    );

    return (
      <React.Fragment>
          <section className="bg-half-50 bg-light d-table w-100">
            <Container>
              <Row className="mt-5 justify-content-center">
                <Col lg={12} className="text-center">
                  <div className="pages-heading">
                    <h4 className="title mb-0">einfach selber buchen</h4>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <div className="position-relative">
            <div className="shape overflow-hidden text-white">
              <svg
                  viewBox="0 0 2880 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                    fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <section className="section">
            <Container>
              <Row className="justify-content-center">
                <div className="search-container">
                  <input
                      type="text"
                      placeholder="Thema suchen..."
                      className="search-box"
                      value={searchTerm}
                      onChange={handleSearchChange}
                  />
                </div>
              </Row>
              <Row className="justify-content-center">
                <Col lg={4} md={5} xs={12} className="d-none d-md-block">
                  <div className="rounded shadow p-4 sticky-bar">
                    <ScrollspyNav
                        scrollTargetIds={categories}
                        activeNavclassName="active"
                        scrollDuration="800"
                        headerBackground="true"
                        className="sticky-bar"
                    >
                      <ul className="list-unstyled sidebar-nav mb-0 py-0">
                        <li className="navbar-item p-0">
                          <a href="#newfibu3" className="h6 text-dark navbar-link">
                            Neu bei fibu3
                          </a>
                        </li>
                        <li className="navbar-item mt-3 p-0">
                          <a href="#accounting" className="h6 text-dark navbar-link">
                            Fragen zur Buchhaltung
                          </a>
                        </li>
                        <li className="navbar-item mt-3 p-0">
                          <a href="#article" className="h6 text-dark navbar-link">
                            Fragen zu Artikel
                          </a>
                        </li>
                        <li className="navbar-item mt-3 p-0">
                          <a href="#offerInvoice" className="h6 text-dark navbar-link">
                            Fragen zu Offerten/Rechnungen
                          </a>
                        </li>
                        <li className="navbar-item mt-3 p-0">
                          <a href="#general" className="h6 text-dark navbar-link">
                            Allgemeine Fragen
                          </a>
                        </li>
                      </ul>
                    </ScrollspyNav>
                  </div>
                </Col>

                <Col lg={8} md={7} xs={12}>
                  {!hasVisibleQuestions ? (
                      <div className="faq-content mt-4 pt-1">
                        <span>Kein Video gefunden</span>
                      </div>
                  ) : (<div></div>)
                  }
                  <div className="section-title-nomargin" id="newfibu3">
                    {getFilteredQuestions("newfibu3").filter(({isVisible}) => isVisible).length > 0 && (
                        <h4>Neu bei fibu3</h4>
                    )}
                  </div>
                  <div className="faq-content mt-4 pt-1" id="newfibu3">
                    {getFilteredQuestions("newfibu3").filter(({isVisible}) => isVisible).length > 0 ? (
                        <div className="accordion-container">
                          {getFilteredQuestions("newfibu3").map(
                              ({id, question, videoSrc, isVisible, text}) =>
                                  isVisible && (
                                      <VideoAccordion
                                          key={id}
                                          question={question}
                                          videoSrc={videoSrc}
                                          isVisible={isVisible}
                                          isActive={activeAccordion === id} // Check if this accordion is active
                                          text={text}
                                          onToggle={() => handleAccordionToggle(id)} // Handle accordion toggle
                                      />
                                  )
                          )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                  </div>

                  <div className="section-title" id="accounting">
                    {getFilteredQuestions("accounting").filter(({isVisible}) => isVisible).length > 0 && (
                        <h4>Buchaltung</h4>
                    )}
                  </div>
                  <div className="faq-content mt-4 pt-1" id="accounting">
                    {getFilteredQuestions("accounting").filter(({isVisible}) => isVisible).length > 0 ? (
                        <div className="accordion-container">
                          {getFilteredQuestions("accounting").map(
                              ({id, question, videoSrc, isVisible, text}) =>
                                  isVisible && (
                                      <VideoAccordion
                                          key={id}
                                          question={question}
                                          videoSrc={videoSrc}
                                          isVisible={isVisible}
                                          isActive={activeAccordion === id} // Check if this accordion is active
                                          text={text}
                                          onToggle={() => handleAccordionToggle(id)} // Handle accordion toggle
                                      />
                                  )
                          )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                  </div>

                  <div className="section-title" id="article">
                    {getFilteredQuestions("article").filter(({isVisible}) => isVisible).length > 0 && (
                        <h4>Artikel</h4>
                    )}
                  </div>
                  <div className="faq-content mt-4 pt-1" id="article">
                    {getFilteredQuestions("article").filter(({isVisible}) => isVisible).length > 0 ? (
                        <div className="accordion-container">
                          {getFilteredQuestions("article").map(
                              ({id, question, videoSrc, isVisible, text}) =>
                                  isVisible && (
                                      <VideoAccordion
                                          key={id}
                                          question={question}
                                          videoSrc={videoSrc}
                                          isVisible={isVisible}
                                          isActive={activeAccordion === id} // Check if this accordion is active
                                          text={text}
                                          onToggle={() => handleAccordionToggle(id)} // Handle accordion toggle
                                      />
                                  )
                          )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                  </div>

                  <div className="section-title" id="offerInvoice">
                    {getFilteredQuestions("offerInvoice").filter(({isVisible}) => isVisible).length > 0 && (
                        <h4>Offerten und Rechnungen</h4>
                    )}
                  </div>
                  <div className="faq-content mt-4 pt-1" id="offerInvoice">
                    {getFilteredQuestions("offerInvoice").filter(({isVisible}) => isVisible).length > 0 ? (
                        <div className="accordion-container">
                          {getFilteredQuestions("offerInvoice").map(
                              ({id, question, videoSrc, isVisible, text}) =>
                                  isVisible && (
                                      <VideoAccordion
                                          key={id}
                                          question={question}
                                          videoSrc={videoSrc}
                                          isVisible={isVisible}
                                          isActive={activeAccordion === id} // Check if this accordion is active
                                          text={text}
                                          onToggle={() => handleAccordionToggle(id)} // Handle accordion toggle
                                      />
                                  )
                          )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                  </div>

                  <div className="section-title" id="general">
                    {getFilteredQuestions("general").filter(({isVisible}) => isVisible).length > 0 && (
                        <h4>Allgemeine Fragen</h4>
                    )}
                  </div>
                  <div className="faq-content mt-4 pt-1" id="general">
                    {getFilteredQuestions("general").filter(({isVisible}) => isVisible).length > 0 ? (
                        <div className="accordion-container">
                          {getFilteredQuestions("general").map(
                              ({id, question, videoSrc, isVisible, text}) =>
                                  isVisible && (
                                      <VideoAccordion
                                          key={id}
                                          question={question}
                                          videoSrc={videoSrc}
                                          isVisible={isVisible}
                                          isActive={activeAccordion === id} // Check if this accordion is active
                                          text={text}
                                          onToggle={() => handleAccordionToggle(id)} // Handle accordion toggle
                                      />
                                  )
                          )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                  </div>


                </Col>
              </Row>
            </Container>

          </section>
      </React.Fragment>
    );
  }
}


export default LearningVideos;
